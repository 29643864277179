import './App.css';
import ForceGraph3D from '3d-force-graph';
import relations from './data/relations.json'
//import attacks from './data/attack.json'
import * as THREE from 'three';
import { useEffect, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import positions from './data/positions.json'

const fireWall = './data/3data-icons-firewall.glb'
const switchDev = './data/3data-icons-core-switch.glb'
const wifiap = './data/3data-icons-AP.glb'
const asa = './data/3data-icons-ASA.glb'
const DMZ = './data/3data-icons-DMZ.glb'
const computer = './data/Computer-Set.glb'
const LAN = './data/3data-icons-LAN.glb'
const WAN = './data/3data-icons-WAM.glb'
const GOC1 = './data/3data-icons-G-O-circle-1.glb'
const GOC2 = './data/3data-icons-G-0-circle-2.glb'
const GOB = './data/3data-icons-G-0-box.glb'
const VSS = './data/3data-icons-VSS.glb'
const unit = './data/3data-icons-unit-primary.glb'
const PMULTIPLE = 1000

function App() {
  const [nodesLoaded, setNodesLoaded] = useState(0)
  const loader = new GLTFLoader()

  let allPositions = positions
  console.log(allPositions)

  // we have one useEffect that runs once, loops each node
  // and increments a counter once the gltf loads
  useEffect(() => {
    allPositions.forEach((node, i) => {
      switch (node.type) {
        case 1:
          loader.load(fireWall, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 0:
          loader.load(switchDev, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 2:
          loader.load(wifiap, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 3:
          loader.load(asa, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 4:
          loader.load(DMZ, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 5:
          loader.load(unit, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            gltf.scene.rotateX(1.5)
            setNodesLoaded(i);
          })
          break;
        case 6:
          loader.load(LAN, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            gltf.scene.rotateX(1.5)
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 7:
          loader.load(WAN, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 8:
          loader.load(GOC1, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 9:
          loader.load(GOC2, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 10:
          loader.load(GOB, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 11:
          loader.load(VSS, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
        case 11:
          loader.load(unit, function (gltf) {
            gltf.scene.scale.multiplyScalar(10);
            node.gltfObj = gltf.scene
            setNodesLoaded(i);
          })
          break;
      }
    })
  }, [])

  // we have another useEffect that depends on the state variable nodesLoaded
  // and only when we have a counter that is equal to the 0-indexed length
  // do we process and render
  useEffect(() => {
    if(nodesLoaded === allPositions.length-1){
      const nodes = []
      
      allPositions.forEach((node) => {
        nodes.push({
          id: node.id,
          name: node.name,
          type: node.type,
          gltfObj: node.gltfObj,
          fx: node.position[0]*PMULTIPLE, 
          fy: node.position[1]*PMULTIPLE, 
          fz: node.position[2]*PMULTIPLE, 
          val: (node.scale[0] + node.scale[1] + node.scale[2] + 1),
          sx: node.scale[0],
          sy: node.scale[1],
          sz: node.scale[2],
          r: node.colour[0],
          g: node.colour[1],
          b: node.colour[2],
          a: node.colour[3],
        })
      })
  
      const links = []
      //const attackLinks = []
      relations.forEach((link) => {
        links.push({
          source: link.s,
          target: link.d,
          r: link.r,
          g: link.g,
          b: link.b,
          a: link.a,
        })
      })

      /* attacks.forEach((attack) => {
        attackLinks.push({
          source: attack.s,
          target: attack.d,
          r: attack.r,
          g: attack.g,
          b: attack.b,
          a: attack.a,
        })
      }) */
      
      const gData = {
        nodes: nodes,
        links: links
      };
  
      const Graph = ForceGraph3D()(document.getElementById('graph-3d'))
        .linkWidth(1)
        .nodeThreeObject((node) => {
          return node.gltfObj
        })
        .linkMaterial((link) => {
          return (new THREE.MeshLambertMaterial({
            color: new THREE.Color(link.r, link.g, link.b),
            transparent: true,
            opacity: link.a*10
          }))
        })
        .onNodeClick(node => {
          const distance = 10;
          const distRatio = 1 + distance/Math.hypot(node.sx, node.sy, node.sz);
  
          Graph.cameraPosition(
            { x: node.sx * distRatio, y: node.sy * distRatio, z: node.sz * distRatio },
            node,
            2000
          );
        })
        .cameraPosition({x: 100, y: 0, z: 300})
        //.zoomToFit(0, 100, node => true)
        .graphData(gData)
        //.emitParticle(attack)
        setInterval(() => {
          links.forEach((link) => {
            if(link.r === 1.0){
              Graph.linkDirectionalParticleSpeed(0.0125)
              Graph.linkDirectionalParticleWidth(2)
              Graph.linkDirectionalParticles(1)
              Graph.linkDirectionalParticleColor(function(link) {
                if (link.r === 1.0) {
                  return "gray"
                } else {
                  return "green"
                }
              })
              Graph.emitParticle(link)
            }
          })
        }, 500)
    }
      
  }, [nodesLoaded])

  return (
    <div className="App">
      <div id="graph-3d" />
    </div>
  );
}

export default App;
